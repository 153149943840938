import App from '../app';

class Home extends App {
  registerViews(AppLoader) {
    super.registerViews(AppLoader);
    AppLoader.registerView('home-controller', this.rootElement.querySelectorAll('.js-home-controller'));
  }
}

new Home().start();
